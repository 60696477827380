import { Alert, Button, Collapse, FormControl, Grid, IconButton, Input, InputLabel, Typography } from '@mui/material';
import { Box } from '@mui/system';
import GoogleMapReact from 'google-map-react';

import CloseIcon from '@mui/icons-material/Close';
import bgImg from 'assets/images/bg-a.svg';
import InstaLogo from 'assets/images/iconos/Icono_mapa.svg';
import tabA from 'assets/images/tab-a.svg';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { sendContactMail } from 'services/mail.service';
import SelectServiceChip from './select-services/select';

const MyMarker = () => <img src={InstaLogo} alt="mapIcon" width="50px" height="50px" />;

function TransitionAlerts({ open, setOpen }) {
    if(open){
        setTimeout(() => {
            setOpen(false)
        }, 7000)
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Collapse in={open}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() =>
                                setOpen(false)
                                
                            }
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    Mail enviado con Exito !
                </Alert>
            </Collapse>
        </Box>
    );
}

const Contacto = () => {
    const [open, setOpen] = useState(true);
    const [dataService, setDataService] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [onErrorForm, setOnErrorForm] = useState(false);

    const showInMapClicked = () => {
        //window.open('https://maps.google.com?q=-33.013989,-71.551398');
    };

    const selectedServieData = (dataArray) => {
        console.log('selectedServieData', dataArray);
        if (dataArray?.length > 0) {
            setDataService(dataArray);
        }
    };

    const handlerSendContactForm = () => {
        if (!name || !email || !phone) {
            setOnErrorForm(true);
            return;
        }
        const dataSend = {
            name: name.trim(),
            email: email.trim(),
            phone: phone.trim(),
            message: message.trim(),
            services: dataService
        };
        setOpen(true);
        sendContactMail(dataSend?.email, dataSend);
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setDataService([]);
    };

    return (
        <div>
            <Grid containter direction="row-reverse" alignItems="center" style={{ backgroundColor: 'white', height: '50px' }}>
                <img src={tabA} alt="tab" height={75} style={{ margin: '0 70% 0 4rem' }} />
            </Grid>
            <div
                style={{
                    backgroundColor: `#e0c26e`
                }}
            >
                <div
                    style={{
                        backgroundImage: `url(${bgImg})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                >
                    <Box sx={{ flexGrow: 1 }} style={{ padding: '2rem 1rem' }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item container style={{ backgroundColor: 'white', margin: '3rem 0rem' }} xs={12} sm={9} md={10}>
                                <Grid item container xs={12} sm={7} md={7}>
                                    <div id="elmMap" className="elmMap" style={{ minHeight: '300px', height: '100%', width: '100%' }}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{
                                                key: 'AIzaSyDgJmmbN6txzz8pgAz5JVcUUXxrBCw8jFM',
                                                language: 'es',
                                                region: 'LA'
                                            }}
                                            defaultCenter={{ lat: -22.479913, lng: -68.901679 }}
                                            defaultZoom={18}
                                            yesIWantToUseGoogleMapApiInternals
                                            // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                            onClick={() => showInMapClicked()}
                                        >
                                            <MyMarker key="rocktest" lat={-22.479913} lng={-68.901679} />
                                        </GoogleMapReact>
                                    </div>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    sm={5}
                                    md={5}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ color: '#802161', padding: '0rem 3rem' }}
                                >
                                    <Typography
                                        variant="body1"
                                        noWrap
                                        style={{
                                            width: '100%',
                                            fontSize: `${isMobile ? '3rem' : '5rem'}`,
                                            fontFamily: 'FuturaNormal',
                                            marginBottom: '3rem',
                                            marginTop: '2rem'
                                        }}
                                    >
                                        Hablemos
                                    </Typography>
                                    <SelectServiceChip selectedServices={selectedServieData} />
                                    <FormControl variant="standard" fullWidth style={{ color: '#802161' }}>
                                        <InputLabel htmlFor="name">Tu nombre</InputLabel>
                                        <Input
                                            id="name"
                                            placeholder="Nombre completo"
                                            defaultValue=""
                                            onChange={(e) => {
                                                setName(e.target.value);
                                                setOnErrorForm(false)
                                            }}
                                            value={name}
                                        />
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel htmlFor="email">Correo</InputLabel>
                                        <Input
                                            id="email"
                                            type="mail"
                                            placeholder="ejemplo@ejemplo.com"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setOnErrorForm(false)
                                            }}
                                            value={email}
                                        />
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel htmlFor="phone">Número de teléfono</InputLabel>
                                        <Input
                                            id="phone"
                                            placeholder="+56 9 8765 4321"
                                            onChange={(e) => {
                                                setPhone(e.target.value);
                                                setOnErrorForm(false)
                                            }}
                                            value={phone}
                                        />
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel htmlFor="mensaje">Deja tu mensaje</InputLabel>
                                        <Input
                                            id="mensaje"
                                            placeholder="Mensaje"
                                            onChange={(e) => {
                                                setMessage(e.target.value);
                                                setOnErrorForm(false)
                                            }}
                                            value={message}
                                        />
                                    </FormControl>
                                    <FormControl variant="standard" fullWidth style={{ paddingTop: '2rem' }}>
                                        {onErrorForm ? (
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    fontSize: '1rem',
                                                    color: 'red',
                                                    fontFamily: 'FuturaNormal'
                                                }}
                                            >
                                                * Favor completa todos los datos
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </FormControl>
                                    <TransitionAlerts open={open} setOpen={setOpen} />
                                    <br></br>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            fontFamily: 'FuturaNormal',
                                            fontSize: '25px',
                                            marginTop: '4rem',
                                            borderRadius: '35px',
                                            padding: '0.5rem 3rem',
                                            marginBottom: '3rem',
                                            borderColor: '#89296a',
                                            borderWidth: '3.3px'
                                        }}
                                        onClick={handlerSendContactForm}
                                    >
                                        <Typography
                                            variant="body1"
                                            style={{
                                                fontSize: '1.5rem',
                                                color: 'inherit',
                                                fontFamily: 'FuturaNormal'
                                            }}
                                        >
                                            Enviar
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default Contacto;
