import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unsetSnackbar } from 'store/slices/snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//["error","info","success","warning"]
const CustomSnackbar = () => {
    const { isOpen, type, message } = useSelector((state) => state.snackbar);
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(unsetSnackbar());
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(unsetSnackbar());
    };

    return (
        <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;
