// material-ui
import { Grid, Icon, Typography } from '@mui/material';

// project imports
import Logo from 'assets/images/logo-primary.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Grid container>
        <Grid item xs={3} md={3}>
            <Icon sx={{ display: { xs: 'none', md: 'flex' }, mr: 0 }} style={{ minHeight: '100%', width: 'auto', color: '#802161' }}>
                <img src={Logo} alt="icon" color="#802161" style={{ height: '100%', color: '#802161' }} />
            </Icon>
        </Grid>
        <Grid item xs={9} md={9}>
            <Typography
                variant="body1"
                noWrap
                component="a"
                href="/"
                sx={{
                    mr: 2,
                    fontSize: '20px',
                    display: { xs: 'none', md: 'flex' },
                    fontWeight: 700,
                    fontFamily: 'FuturaBold',
                    letterSpacing: '.2rem',
                    color: '#802161',
                    textDecoration: 'none'
                }}
            >
                ROCKTEST
            </Typography>
            <Typography
                variant="body1"
                noWrap
                component="a"
                href="/"
                sx={{
                    mr: 2,
                    fontSize: '3px',
                    display: { xs: 'none', md: 'flex' },
                    fontWeight: 700,
                    fontFamily: 'FuturaNormal',
                    letterSpacing: '.1rem',
                    color: 'inherit',
                    textDecoration: 'none'
                }}
            >
                SERVICIOS GEOLOGICOS Y GEOTECNICOS
            </Typography>
        </Grid>
    </Grid>
);

export default LogoSection;
