import { authentication } from 'configs/firebase';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setUserByEmail } from 'services/users.service';

export const useAuthListener = () => {
    //const { id, type, message } = useSelector((state) => state.userSesion);
    // assume user to be logged out
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // keep track to display a spinner while auth status is being checked
    const [checkingStatus, setCheckingStatus] = useState(true);
    const { email } = useSelector((state) => state.userSession);

    useEffect(() => {
        // auth listener to keep track of user signing in and out
        authentication.onAuthStateChanged((user) => {
            console.log({ user, email: user?.email, userSessionEmail: email });
            if (email !== user?.email) {
                setUserByEmail(user?.email)
                    .then(() => {
                        setIsLoggedIn(true);
                        setCheckingStatus(false);
                    })
                    .catch((err) => {
                        console.error({ step: 'AUTH', err });
                    });
            } else {
                setIsLoggedIn(true);
                setCheckingStatus(false);
            }
        });
    }, [email]);

    return { isLoggedIn, checkingStatus };
};
