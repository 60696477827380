import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Grow, Typography } from '@mui/material';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            avatar,
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            animate = false,
            subheader,
            onClick,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();

        return (
            <Grow in={true}>
                <Card
                    ref={ref}
                    {...others}
                    sx={{
                        border: border ? '1px solid' : 'none',
                        borderColor: theme.palette.primary[200] + 75,
                        ':hover': {
                            boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                        },
                        ...sx
                    }}
                    onClick={onClick}
                >
                    {/* card header and action */}
                    {!darkTitle && title && (
                        <CardHeader avatar={avatar} sx={headerSX} title={title} subheader={subheader} action={secondary} />
                    )}
                    {darkTitle && title && (
                        <CardHeader
                            sx={headerSX}
                            title={<Typography variant="h3">{title}</Typography>}
                            subheader={subheader}
                            action={secondary}
                        />
                    )}

                    {/* content & header divider */}
                    {title && <Divider />}

                    {/* card content */}
                    {content && (
                        <CardContent sx={contentSX} className={contentClass}>
                            {children}
                        </CardContent>
                    )}
                    {!content && children}
                </Card>
            </Grow>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    avatar: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    animate: PropTypes.bool,
    onClick: PropTypes.object
};

export default MainCard;
