import { CardMedia, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import tabW from 'assets/images/tab-w.svg';
import POZO from 'assets/images/iconos/Icono_Pozo.svg';
import { isMobile } from 'react-device-detect';
import Footer from '../footer/Footer';
import Contacto from '../contacto/Contacto';
import Header from '../header/Header';

const LandingGeoService = () => {
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Header />
                <div style={{ backgroundColor: 'white', width: '100%', height: `${isMobile ? '80px' : '100px'}` }}></div>
            </Box>
            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: '#802161' }}>
                <Grid container justifyContent="center" style={{ padding: '2rem 2rem' }}>
                    {isMobile ? (
                        <Typography
                            variant="h1"
                            style={{ fontSize: '2.5rem', color: 'white', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                        >
                            SERVICIOS
                        </Typography>
                    ) : (
                        <Typography
                            variant="h1"
                            style={{ fontSize: '5rem', color: 'white', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                        >
                            SERVICIOS
                        </Typography>
                    )}
                </Grid>
                <Grid containter direction="row-reverse" alignItems="center" style={{ backgroundColor: '#802161', height: '43px' }}>
                    <img src={tabW} alt="tab" height={45} style={{ margin: '0 4rem 0 70%' }} />
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: 'white', padding: '6rem 2rem', zIndex: '1', position: 'relative' }}>
                <Grid container justifyContent="center">
                    <Grid item container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" paddingBottom={'4rem'}>
                        <Grid item xs={12} md={1} justifyContent="flex-end">
                            <CardMedia
                                component="img"
                                height="120"
                                image={POZO}
                                alt="green iguana"
                                style={{ backgroundColor: 'white', objectFit: 'contain', paddingBottom: '2rem' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography
                                variant="h1"
                                style={{ fontSize: '45px', color: '#802161', fontFamily: 'FuturaNormal', marginBottom: '2rem' }}
                            >
                                SERVICIOS GEOLÓGICOS Y GEOTÉCNICOS
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={12} justifyContent="center" paddingX={isMobile ? '0rem' : '3rem'}>
                        <Grid item container xs={12} md={12} justifyContent="flex-start" alignItems="center" direction="column">
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{ fontSize: isMobile ? '18px' : '26px', color: '#8e3a73', fontFamily: 'FuturaMediana' }}
                            >
                                La Geotecnia es la rama de la Ingeniería Civil e Ingeniería Geológica que se encarga del estudio de las
                                propiedades mecánicas, hidráulicas e ingenieriles de los materiales provenientes de la tierra. En tanto, la
                                Geología es la ciencia que estudia la composición y estructura tanto interna como superficial de la tierra,
                                y los procesos por los cuales ha ido evolucionando a lo largo del tiempo geológico.
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} md={6} justifyContent="flex-start" direction="column">
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                Nuestra gama de servicios son:
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '12px'
                                }}
                            >
                                {`>`}Control y Supervisión de Sondajes.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Hidrogeología aplicada.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Diseño, construcción y gestión de datos Geológicos, Estructurales y Geotécnicos.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Instrumentación Geotécnica.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Ground Control Geotécnico.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Caracterización Geotécnica.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Modelamiento Geotécnico, Estructural y Geológico.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Saneamiento Minero.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Control y gestión de radares para el control de estabilidad de taludes.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Contacto />
            <Footer />
        </>
    );
};

export default LandingGeoService;
