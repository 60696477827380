import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    createAt: '',
    description: '',
    email: '',
    id: '',
    name: '',
    status: false,
    updateAt: ''
};

export const userDetailsSlice = createSlice({
    name: 'userDetails',
    initialState: initialState,
    reducers: {
        setUserDetails: (state, action) => {
            console.log(state, action.payload);
            state.createAt = action.payload.createAt;
            state.description = action.payload.description;
            state.email = action.payload.email;
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.status = action.payload.status;
            state.updateAt = action.payload.updateAt;
        },
        unsetUserDetails: (state) => {
            state.createAt = '';
            state.description = '';
            state.email = '';
            state.id = '';
            state.name = '';
            state.status = false;
            state.updateAt = '';
        }
    }
});

export const { setUserDetails, unsetUserDetails } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
