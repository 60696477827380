import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    clientId: '',
    email: '',
    userId: '',
    userRol: '',
    userName: '',
    clientDetails: {}
};
export const userSessionSlice = createSlice({
    name: 'userSession',
    initialState: initialState,
    reducers: {
        setUserSession: (state, action) => {
            state.clientId = action.payload?.clientId;
            state.email = action.payload?.email;
            state.userId = action.payload?.userId;
            state.userRol = action.payload?.userRol;
            state.userName = action.payload?.userName;
            state.clientDetails = action.payload?.clientDetails;
        },
        unsetUserSession: (state) => {
            state.clientId = '';
            state.email = '';
            state.userId = '';
            state.userRol = '';
            state.userName = '';
            state.clientDetails = {};
        }
    }
});

export const { setUserSession, unsetUserSession } = userSessionSlice.actions;

export default userSessionSlice.reducer;
