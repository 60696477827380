import { Checkbox, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
const service1 = [
    'A partir de Colpas Perforaciones sobre muestras que no califican',
    'A partir de Colpas con Planos de Debilidad (Slickensides)',
    'A partir de Testigos de Sondajes',
    'Corte de Roca Específico',
    'Corte de Testigo de Roca Longitudinal',
    'Molde de Yeso para Ensayo Hoek'
];
const service2 = [
    'Peso Unitario con y sin Parafina',
    'Peso Unitario Seco',
    'Peso Unitario Saturado',
    'Peso Unitario Geométrico',
    'Peso Unitario en Picnómetro',
    'Porosidad',
    'Velocidades de Onda (Vp-Vs) y Módulo Dinámico hasta OC (6”)',
    '% de Humedad',
    'Dureza Schmidt',
    'Fricción con Mesa de Fricción (Angulo de Fricción Básico)',
    'Slake Durability'
];
const service3 = [
    `Compresión Uniaxial Simple y con Deformación Axial y Radial con Módulos Elásticos Estáticos (Strain Gages - Youn's/Poisson)`,
    'Cizalle Con Celda Hoek',
    'Hasta 40 cm2 (3 Cargas Normales), Carga Adicional (Hasta 40 cm2)',
    'Hasta 900 cm2 (3 Cargas normales), Carga Adicional (Hasta 900 cm2)',
    'Compresión Triaxial Simple (50-100-150 o 200 Kg/cm2)',
    'Con deformación Axial y Radial con Módulos Elásticos',
    'Ensayo de Tracción Indirecta (Método Brasileño)',
    'Indirecta (Método Brasileño - 2 a 3 Discos por ensayo)',
    'Índice de Carga Puntual (Diametral-Axial-Irregular)',
    'Fotografías',
    'Informe Técnico (Escrito y Magnético)'
];
const servicesSelect = {
    title: 'Laboratorio Geotécnico',
    childs: [
        {
            subTitle: 'Preparación de Muestras',
            names: service1
        },
        {
            subTitle: 'Ensayos No Destructivos',
            names: service2
        },
        {
            subTitle: 'Ensayos destructivos',
            names: service3
        }
    ]
};
const servicesSelect2 = {
    title: 'Geofísica de pozo',
    childs: [
        {
            subTitle: 'Medición de Trayectoria de Pozos con Giroscopio',
            names: ['Medición de Trayectoria de Pozos con Giroscopio']
        },
        {
            subTitle: 'Orientación de Estructuras de Pozos',
            names: [
                'orientación de estructuras',
                'medición de abertura de estructuras',
                'orientación de perforaciones',
                'correlaciones litológicas',
                'determinación de calidad de la roca',
                'identificación de estratificación y mineralización'
            ]
        }
    ]
};
const servicesSelect3 = {
    title: 'Servicios Geologicos y geotecnicos',
    childs: [
        {
            subTitle: 'Control y Supervisión de Sondajes',
            names: [
                'Control y Supervisión de Sondajes',
                'Hidrogeología aplicada',
                'Diseño, construcción y gestión de datos Geológicos, Estructurales y Geotécnicos',
                'Instrumentación Geotécnica',
                'Ground Control Geotécnico',
                'Caracterización Geotécnica',
                'Modelamiento Geotécnico, Estructural y Geológico',
                'Saneamiento Minero',
                'Control y gestión de radares para el control de estabilidad de taludes'
            ]
        }
    ]
};

export default function SelectServiceChip({ selectedServices, ...other }) {
    const [valuesSelected, setValuesSelected] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setValuesSelected(typeof value === 'string' ? value.split(',') : value);
        selectedServices(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <FormControl sx={{ m: 1, width: '100%' }} key="SelectServices">
            <InputLabel id="demo-multiple-chip-label">Como podemos ayudarte</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={valuesSelected}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                <MenuItem key={servicesSelect.title} style={{ color: 'black', fontWeight: 'bold' }}>
                    {servicesSelect.title}
                </MenuItem>
                {servicesSelect.childs.map((child) => {
                    const mapResult = child.names.map((name) => {
                        return (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={valuesSelected.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        );
                    });
                    return [<MenuItem key={child.subTitle}>{child.subTitle}</MenuItem>].concat(mapResult);
                })}

                <MenuItem key={servicesSelect2.title} style={{ color: 'black', fontWeight: 'bold' }}>
                    {servicesSelect2.title}
                </MenuItem>
                {servicesSelect2.childs.map((child) => {
                    const mapResult = child.names.map((name) => {
                        return (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={valuesSelected.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        );
                    });
                    return [<MenuItem key={child.subTitle}>{child.subTitle}</MenuItem>].concat(mapResult);
                })}

                <MenuItem key={servicesSelect3.title} style={{ color: 'black', fontWeight: 'bold' }}>
                    {servicesSelect3.title}
                </MenuItem>
                {servicesSelect3.childs.map((child) => {
                    const mapResult = child.names.map((name) => {
                        return (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={valuesSelected.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        );
                    });
                    return [<MenuItem key={child.subTitle}>{child.subTitle}</MenuItem>].concat(mapResult);
                })}
            </Select>
        </FormControl>
    );
}
