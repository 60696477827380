// assets
import { IconUsers, IconBriefcase } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'menú',
    caption: 'administrador',
    type: 'group',
    children: [
        {
            id: 'clients',
            title: 'Clientes',
            type: 'item',
            url: '/dashboard/clients',
            icon: IconBriefcase,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Usuarios',
            type: 'item',
            url: '/dashboard/users',
            icon: IconUsers,
            userRol: 'ADMIN',
            breadcrumbs: false
        }
    ]
};

export default dashboard;
