import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Drawer, Grid, Icon, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import Logo from 'assets/images/logo-white.svg';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
// ==============================|| HOME PAGE ||============================== //

const pages = ['Inicio', 'Nosotros', 'Servicios', 'Galeria', 'Contacto', 'Ingresar'];

const Header = (props) => {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handlerLogin = () => {
        navigate('/login');
    };

    const redirectHome = (value) => {
        if (isMobile) {
            setTimeout(() => {
                props?.[`ref${value}`]?.current?.scrollIntoView({ behavior: 'smooth' });
            }, 500);
        } else {
            props?.[`ref${value}`]?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const drawer = (
        <Box onClick={handleCloseNavMenu} sx={{ textAlign: 'center' }}>
            <Grid container direction="column">
                <Typography
                    variant="body1"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        fontSize: '30px',
                        display: { md: 'flex' },
                        fontWeight: 700,
                        fontFamily: 'FuturaBold',
                        letterSpacing: '.5rem',
                        color: '#89296a',
                        textDecoration: 'none'
                    }}
                >
                    ROCKTEST
                </Typography>
                <Typography
                    variant="body1"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        fontSize: '8px',
                        display: { md: 'flex' },
                        fontWeight: 700,
                        fontFamily: 'FuturaNormal',
                        letterSpacing: '.1rem',
                        color: '#89296a',
                        textDecoration: 'none'
                    }}
                >
                    SERVICIOS GEOLOGICOS Y GEOTECNICOS
                </Typography>
            </Grid>
            <Divider />
            <List>
                {pages?.map((page) => (
                    <ListItem key={page} disablePadding>
                        <ListItemButton
                            sx={{ textAlign: 'center' }}
                            onClick={() => {
                                if (page === 'Ingresar') {
                                    handlerLogin();
                                } else {
                                    redirectHome(page);
                                }
                            }}
                        >
                            <ListItemText primary={page} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    const container = window !== undefined ? () => window.document.body : undefined;
    return (
        <>
            <AppBar component="nav" position="absolute" elevation={4}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters style={{ fontFamily: 'FuturaNormal' }}>
                        <Icon
                            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                            textAlign="center"
                            style={{ minHeight: '50px', width: 'auto' }}
                        >
                            <img src={Logo} alt="icon" color="white" style={{ height: '100%' }} />
                        </Icon>
                        <Grid>
                            <Typography
                                variant="body1"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    fontSize: '30px',
                                    display: { xs: 'none', md: 'flex' },
                                    fontWeight: 700,
                                    fontFamily: 'FuturaBold',
                                    letterSpacing: '.5rem',
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }}
                            >
                                ROCKTEST
                            </Typography>
                            <Typography
                                variant="body1"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    fontSize: '8px',
                                    display: { xs: 'none', md: 'flex' },
                                    fontWeight: 700,
                                    fontFamily: 'FuturaNormal',
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }}
                            >
                                SERVICIOS GEOLOGICOS Y GEOTECNICOS
                            </Typography>
                        </Grid>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left'
                                }}
                                open={false}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' }
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem
                                        key={page}
                                        onClick={() => {
                                            if (page === 'Ingresar') {
                                                handlerLogin();
                                            } else {
                                                redirectHome(page);
                                            }
                                        }}
                                    >
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Icon
                            sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
                            textAlign="center"
                            style={{ minHeight: '40px', width: 'auto' }}
                        >
                            <img src={Logo} alt="icon" color="white" style={{ height: '100%' }} />
                        </Icon>
                        <Grid>
                            <Typography
                                variant="body1"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    fontSize: '23px',
                                    display: { xs: 'flex', md: 'none' },
                                    fontWeight: 700,
                                    fontFamily: 'FuturaBold',
                                    letterSpacing: '.5rem',
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }}
                            >
                                ROCKTEST
                            </Typography>
                            <Typography
                                variant="body1"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    fontSize: '6px',
                                    display: { xs: 'flex', md: 'none' },
                                    fontWeight: 700,
                                    fontFamily: 'FuturaNormal',
                                    letterSpacing: '.1rem',
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }}
                            >
                                SERVICIOS GEOLOGICOS Y GEOTECNICOS
                            </Typography>
                        </Grid>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={() => {
                                        console.log('TEST', page);
                                        if (page === 'Ingresar') {
                                            handlerLogin();
                                        } else {
                                            redirectHome(page);
                                        }
                                    }}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box component="nav">
                <Drawer
                    anchorEl={anchorElNav}
                    id="menu-appbar"
                    container={container}
                    variant="temporary"
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { flex: 1, boxSizing: 'border-box', width: '70vw' }
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </>
    );
};

export default Header;
