// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyC2cqax6CxbqYBTbOftB_o0X786Iu9k3AM',
    authDomain: 'geosis-f07e6.firebaseapp.com',
    projectId: 'geosis-f07e6',
    storageBucket: 'geosis-f07e6.appspot.com',
    messagingSenderId: '878126379865',
    appId: '1:878126379865:web:a67b228afcb26da036d248',
    measurementId: 'G-HS65PEE2VG'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const authentication = getAuth(app);
const functions = getFunctions(app);

export { app, db, storage, authentication, functions, analytics };
