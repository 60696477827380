import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { isMobile } from 'react-device-detect';

import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import RRSS_1 from 'assets/images/iconos/Icono RRSS_Amarillo claro (1).svg';
import RRSS_4 from 'assets/images/iconos/Icono RRSS_Amarillo claro (4).svg';
import LogoAmarillo from 'assets/images/logo_amarillo2.png';
const Footer = () => {
    return (
        <>
            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: 'rgb(146 40 111)', padding: '2rem 2rem' }}>
                <Grid container justifyContent="center">
                    <Grid intem justifyContent="center" style={{ border: '' }}>
                        <Typography
                            variant="h1"
                            style={{ fontSize: '2rem', color: 'white', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                        >
                            Quiero suscribirme
                        </Typography>
                    </Grid>
                    <Grid item justifyContent="center"></Grid>
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: '#802161', padding: '2rem 2rem' }}>
                <Grid container justifyContent="center">
                    <Grid item container justifyContent="center" xs={12} md={4}>
                        <img src={LogoAmarillo} alt="logofooter" height={200} style={{ margin: '2rem 0rem' }} />
                    </Grid>
                    <Grid item container xs={12} md={5} style={{ color: '#e0c26e', padding: '2rem 0rem' }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1" style={{ fontSize: '1.5rem', color: 'white' }}>
                                Experiencia
                            </Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Caracterización Geotécnica`}</Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Excavaviones y Mineria`}</Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Excavaciones Superficiales`}</Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Capacitación y Formación`}</Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Servicios en Terreno`}</Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Y mucho más`}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1" style={{ fontSize: '1.5rem', color: 'white' }}>
                                Servicios
                            </Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Servicios en Terreno`}</Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Estudios y Asesorías`}</Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Capacitaciones`}</Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Proyectos Mineros`}</Typography>
                            <Typography variant="body1">{`>  \u00A0\u00A0\u00A0 Geomensura`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction={isMobile ? 'row' : 'column'} justifyContent="space-evenly" xs={12} md={2}>
                        <img
                            src={RRSS_1}
                            onClick={() => {
                                window.open(
                                    'https://api.whatsapp.com/send?phone=56983437490&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20<ROCKTEST>%20.'
                                );
                            }}
                            alt="rrssfooterwhat"
                            height={40}
                            style={{ marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <img
                            src={RRSS_4}
                            onClick={() => {
                                window.open('https://cl.linkedin.com/company/rocktest-spa');
                            }}
                            alt="rrssfooterlinkedin"
                            height={40}
                            style={{ marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <YouTubeIcon
                            onClick={() => {
                                window.open('https://youtube.com');
                            }}
                            style={{ color: '#e5c97f', fontSize: 40, marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <InstagramIcon
                            onClick={() => {
                                window.open('https://www.instagram.com/rocktest.spa/?hl=es');
                            }}
                            style={{ color: '#e5c97f', fontSize: 40, marginLeft: 'auto', marginRight: 'auto' }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: '#802161', padding: `${isMobile ? '' : '1rem 8rem'}` }}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid
                        container
                        direction="row"
                        xs={12}
                        md={10}
                        justifyContent="center"
                        alignItems="center"
                        style={{ color: '#e0c26e' }}
                    >
                        <Grid item xs={12} md={10}>
                            <Typography
                                align="center"
                                variant="body1"
                            >{`©${new Date().getFullYear()} Rocktest Geofísica. Servicios de Laboratorio Geomécanico, Geofisica, Servicios Geológicos y Geotécnicos`}</Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Typography align="center" variant="body1">
                                Diseñado por <strong>garbo</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Footer;
