import { configureStore } from '@reduxjs/toolkit';
import customizationReducer from './slices/customization/customizationReducer';
import snackbarReducer from './slices/snackbar';
import clientDetailsReduce from './slices/clients';
import userSessionReduce from './slices/userSession';
import userDetailsReduce from './slices/users';

// ==============================|| REDUX - MAIN STORE ||============================== //

//const store = createStore(reducer);
const persister = '';

const store = configureStore({
    reducer: {
        clientDetails: clientDetailsReduce,
        snackbar: snackbarReducer,
        customization: customizationReducer,
        userSession: userSessionReduce,
        userDetails: userDetailsReduce
    }
});

export { store, persister };
