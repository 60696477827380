import { Divider, List, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconBriefcase } from '@tabler/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';

const NavGroup = ({ item }) => {
    const theme = useTheme();
    const { userRol } = useSelector((state) => state.userSession);

    // menu list collapse & items
    const getItems = () => {
        if (userRol === 'CLIENT') {
            return [
                {
                    id: 'archivos',
                    title: 'Archivos',
                    type: 'item',
                    url: '/dashboard/files',
                    icon: IconBriefcase,
                    breadcrumbs: false
                }
            ].map((menu) => {
                switch (menu.type) {
                    case 'collapse':
                        return <NavCollapse key={menu.id} menu={menu} level={1} />;
                    case 'item':
                        return <NavItem key={menu.id} item={menu} level={1} />;
                    default:
                        return (
                            <Typography key={menu.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            });
        }
        if (userRol === 'ADMIN' || userRol === 'DEV' || userRol === 'FILES_MANAGER') {
            return item.children
                ?.filter((menu) => {
                    if (menu.userRol === 'ADMIN' && userRol === 'FILES_MANAGER') {
                        return false;
                    }
                    return true;
                })
                .map((menu) => {
                    switch (menu.type) {
                        case 'collapse':
                            return <NavCollapse key={menu.id} menu={menu} level={1} />;
                        case 'item':
                            return <NavItem key={menu.id} item={menu} level={1} />;
                        default:
                            return (
                                <Typography key={menu.id} variant="h6" color="error" align="center">
                                    Menu Items Error
                                </Typography>
                            );
                    }
                });
        }
        return <></>;
    };

    const items = getItems();

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {userRol === 'DEV'
                                        ? 'Desarrollador'
                                        : userRol === 'CLIENT'
                                        ? 'Cliente'
                                        : userRol === 'ADMIN'
                                        ? 'Administrador'
                                        : '...'}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
