import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    type: '',
    message: ''
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: initialState,
    reducers: {
        setSnackbar: (state, action) => {
            state.isOpen = action.payload.isOpen;
            state.type = action.payload.type;
            state.message = action.payload.message;
        },
        unsetSnackbar: (state) => {
            state.isOpen = false;
        }
    }
});

export const { setSnackbar, unsetSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
