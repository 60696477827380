import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { store } from 'store';
import { setUserSession } from 'store/slices/userSession';
import { db, authentication } from '../configs/firebase';

export const createRegistryUser = async (userData) => {
    console.log('userData', userData);
    try {
        userData.userId = userData.email;
        userData.isDeleted = false;
        const docRef = await setDoc(doc(db, `users`, userData.email), userData);
        console.log('createRegistryUser registrado con exito ID: ', docRef);
    } catch (e) {
        console.error('Error adding createRegistryUser: ', e);
    }
};

export const getUsers = async () => {
    try {
        const q = query(collection(db, 'users'), where('isDeleted', '==', false));
        const querySnapshot = await getDocs(q);
        let dataDocs = [];
        querySnapshot.forEach((doc) => {
            dataDocs.push(doc.data());
        });
        return dataDocs;
    } catch (e) {
        console.error('Error adding document: ', e);
    }
};

export const getUsersById = async () => {
    try {
        const uid = authentication?.currentUser?.uid;
        console.log('getUsersById', uid);
        const docSnap = await getDoc(doc(db, 'users', `${uid}`));
        if (docSnap.exists()) {
            console.log('docSnap.data()', docSnap.data());
            store.dispatch(setUserSession(docSnap.data()));
        } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
        }
    } catch (e) {
        console.error('Error finding document: ', e);
    }
};

export const setUserByEmail = async (email) => {
    try {
        const q = query(collection(db, 'users'), where('email', '==', email));
        const querySnapshot = await getDocs(q);
        let dataDocs = [];
        querySnapshot.forEach((doc) => {
            dataDocs.push(doc.data());
        });
        const userDoc = dataDocs?.[0];
        store.dispatch(setUserSession(userDoc));
        return true;
    } catch (e) {
        console.error('Error setUserByEmail: ', e);
        return false;
    }
};

export const getUserByEmail = async (email) => {
    try {
        const q = query(collection(db, 'users'), where('email', '==', email), where('isDeleted', '==', false));
        const querySnapshot = await getDocs(q);
        let dataDocs = [];
        querySnapshot.forEach((doc) => {
            dataDocs.push(doc.data());
        });

        return dataDocs?.[0];
    } catch (e) {
        console.error('Error getUserByClientId: ', e);
        return null;
    }
};

export const getUserByClientId = async (clientId) => {
    try {
        const q = query(collection(db, 'users'), where('clientId', '==', clientId), where('isDeleted', '==', false));
        const querySnapshot = await getDocs(q);
        let dataDocs = [];
        querySnapshot.forEach((doc) => {
            dataDocs.push(doc.data());
        });
        return dataDocs;
    } catch (e) {
        console.error('Error getUserByClientId: ', e);
    }
};

export const deleteUser = async (docId) => {
    try {
        const userRef = doc(db, 'users', docId);
        return await updateDoc(userRef, {
            isDeleted: true
        });
    } catch (e) {
        console.error('[deleteUser] error: ', e);
    }
};
