import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import MinimalLayout from 'layout/MinimalLayout';
import ProtectedRoute from 'views/utilities/ProtectedRoute';
// routes

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import LandingLabService from 'views/landing/services-landing/lab-service';
import LandingPosoService from 'views/landing/services-landing/poso-service';
import LandingGeoService from 'views/landing/services-landing/geo-service';
// project imports
// dashboard routing
const ClientsModule = Loadable(lazy(() => import('views/clients-module')));
const UsersModule = Loadable(lazy(() => import('views/users-module')));
const FilesClientModule = Loadable(lazy(() => import('views/files-client-module')));
// sample page routing
const LandingPage = Loadable(lazy(() => import('views/landing')));

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// ==============================|| ROUTING RENDER ||============================== //

// ==============================|| MAIN ROUTING ||============================== //
export default function ThemeRoutes() {
    //return useRoutes([MainRoutes, AuthenticationRoutes, LandingRouters]);
    return useRoutes([
        {
            path: '/',
            element: <LandingPage />,
            children: [
                {
                    path: '/',
                    element: <LandingPage />
                }
            ]
        },
        {
            path: '/laboratorio-geotecnico',
            element: <LandingLabService />
        },
        {
            path: '/geofisica-de-poso',
            element: <LandingPosoService />
        },
        {
            path: '/servicios-geologicos-y-geotecnicos',
            element: <LandingGeoService />
        },

        {
            path: '/login',
            element: <MinimalLayout />,
            children: [
                {
                    path: '/login',
                    element: <AuthLogin3 />
                }
            ]
        },
        {
            path: '/dashboard',
            element: (
                <ProtectedRoute>
                    <MainLayout />
                </ProtectedRoute>
            ),
            children: [
                {
                    path: 'clients',
                    element: (
                        <ProtectedRoute>
                            <ClientsModule />
                        </ProtectedRoute>
                    )
                },
                {
                    path: 'users',
                    element: (
                        <ProtectedRoute>
                            <UsersModule />
                        </ProtectedRoute>
                    )
                },
                {
                    path: 'files',
                    element: (
                        <ProtectedRoute>
                            <FilesClientModule />
                        </ProtectedRoute>
                    )
                }
            ]
        }
    ]);
}
