import { CardMedia, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import tabW from 'assets/images/tab-w.svg';

import APOYO from 'assets/images/iconos/Icono_Apoyo.svg';
import { isMobile } from 'react-device-detect';
import Footer from '../footer/Footer';
import Contacto from '../contacto/Contacto';
import Header from '../header/Header';

const LandingLabService = () => {
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Header />
                <div style={{ backgroundColor: 'white', width: '100%', height: `${isMobile ? '80px' : '100px'}` }}></div>
            </Box>
            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: '#802161' }}>
                <Grid container justifyContent="center" style={{ padding: '2rem 2rem' }}>
                    {isMobile ? (
                        <Typography
                            variant="h1"
                            style={{ fontSize: '2.5rem', color: 'white', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                        >
                            SERVICIOS
                        </Typography>
                    ) : (
                        <Typography
                            variant="h1"
                            style={{ fontSize: '5rem', color: 'white', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                        >
                            SERVICIOS
                        </Typography>
                    )}
                </Grid>
                <Grid containter direction="row-reverse" alignItems="center" style={{ backgroundColor: '#802161', height: '43px' }}>
                    <img src={tabW} alt="tab" height={45} style={{ margin: '0 4rem 0 70%' }} />
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: 'white', padding: '6rem 2rem', zIndex: '1', position: 'relative' }}>
                <Grid container justifyContent="center">
                    <Grid item container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" paddingBottom={'4rem'}>
                        <Grid item xs={12} md={1} justifyContent="flex-end">
                            <CardMedia
                                component="img"
                                height="120"
                                image={APOYO}
                                alt="green iguana"
                                style={{ backgroundColor: 'white', objectFit: 'contain', paddingBottom: '2rem' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography
                                variant="h1"
                                style={{ fontSize: '45px', color: '#802161', fontFamily: 'FuturaNormal', marginBottom: '2rem' }}
                            >
                                Laboratorio Geotécnico
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={12} justifyContent="center" paddingX={isMobile ? '0rem' : '3rem'}>
                        <Grid item container xs={12} md={12} justifyContent="flex-start" alignItems="flex-start" direction="column">
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{ fontSize: isMobile ? '18px' : '26px', color: '#8e3a73', fontFamily: 'FuturaMediana' }}
                            >
                                Para la obtención de parámetros que permitan caracterizar a los diferentes materiales que se encuentran en
                                el desarrollo de proyectos mineros y civiles, es necesario la realización de ensayos geomecánicos, los
                                cuales nos permitan obtener las propiedades básicas y mecánicas de las rocas, parámetros que se obtienen
                                desarrollando ensayos No Destructivos (Densidad, porosidad, velocidad de ondas, etc.) y ensayos Destructivos
                                (Compresión y Tracción).
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '12px',
                                    width: 'auto'
                                }}
                            >
                                La gama de ensayos desarrollados por Rocktest SpA.:
                            </Typography>
                        </Grid>

                        <Grid item container xs={12} md={4} justifyContent="flex-start" alignItems="flex-start" direction="column">
                            <Typography
                                variant="h1"
                                align="center"
                                style={{ color: '#802161', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                            >
                                Preparación de Muestras
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • A partir de Colpas Perforaciones sobre muestras que no califican.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • A partir de Colpas con Planos de Debilidad (Slickensides).
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • A partir de Testigos de Sondajes.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Corte de Roca Específico.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Corte de Testigo de Roca Longitudinal.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Molde de Yeso para Ensayo Hoek.
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} md={4} justifyContent="flex-start" alignItems="flex-start" direction="column">
                            <Typography
                                variant="h1"
                                align="center"
                                style={{ color: '#802161', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                            >
                                Ensayos No Destructivos:
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Peso Unitario con y sin Parafina.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Peso Unitario Seco.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Peso Unitario Saturado.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Peso Unitario Geométrico.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Peso Unitario en Picnómetro.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Porosidad.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Velocidades de Onda (Vp-Vs) y Módulo Dinámico hasta OC (6”).
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • % de Humedad.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Dureza Schmidt.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Fricción con Mesa de Fricción (Ángulo de Fricción Básico).
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Slake Durability.
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} md={4} justifyContent="flex-start" alignItems="flex-start" direction="column">
                            <Typography
                                variant="h1"
                                align="center"
                                style={{ color: '#802161', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                            >
                                Ensayos Destructivos:
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Compresión Uniaxial Simple y con Deformación Axial y Radial con Módulos Elásticos Estáticos (Strain Gages
                                - Youn's/Poisson).
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Cizalle Con Celda Hoek:
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Hasta 40 cm2 (3 Cargas Normales), Carga Adicional (Hasta 40 cm2).
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Hasta 900 cm2 (3 Cargas normales), Carga Adicional (Hasta 900 cm2).
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Compresión Triaxial Simple (50-100-150 o 200 Kg/cm2):
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Con deformación Axial y Radial con Módulos Elásticos.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Ensayo de Tracción Indirecta (Método Brasileño).
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Indirecta (Método Brasileño - 2 a 3 Discos por ensayo).
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Índice de Carga Puntual (Diametral-Axial-Irregular).
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Fotografías.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                • Informe Técnico (Escrito y Magnético).
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Contacto />
            <Footer />
        </>
    );
};

export default LandingLabService;
