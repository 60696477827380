import { useAuthListener } from 'hooks/useAuthListener';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = (props) => {
    const navigate = useNavigate();

    const { isLoggedIn, checkingStatus } = useAuthListener();

    useEffect(() => {
        console.log('ProtectedRoute');
        if (!checkingStatus && !isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn, checkingStatus, navigate]);

    return <React.Fragment>{isLoggedIn ? props?.children : null}</React.Fragment>;
};
export default ProtectedRoute;
