import { db } from 'configs/firebase';
import { doc, setDoc } from 'firebase/firestore';

export const sendContactMail = async (email, data) => {
    try {
        const dataService = data?.services?.map((ser) => `· ${ser}<br/>`);
        const document = {
            to: 'csoto.rocktest@gmail.com,n.mendoza.dev@gmail.com', //'csoto.rocktest@gmail.com',
            message: {
                subject: `Contacto rocktest.cl - ${email}`,
                html: `Nombre: ${data?.name} <br/>
                    Email: ${email} <br/>
                    Teléfono: ${data?.phone} <br/>
                    Mensaje: ${data?.message} <br/><br/>
                    Servicios: <br/> ${dataService.join()}`
            }
        };

        await setDoc(doc(db, `mail`, Date.now().toString()), document);
        console.log('[sendContactMail], Mail enviado con exito : ', email);
        return document;
    } catch (e) {
        console.error('[sendContactMail] Error adding document: ', e);
    }
};
