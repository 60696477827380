import { CardMedia, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import tabW from 'assets/images/tab-w.svg';

import LAB from 'assets/images/iconos/Icono_LAB.svg';
import { isMobile } from 'react-device-detect';
import Footer from '../footer/Footer';
import Contacto from '../contacto/Contacto';
import Header from '../header/Header';

const LandingPosoService = () => {
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Header />
                <div style={{ backgroundColor: 'white', width: '100%', height: `${isMobile ? '80px' : '100px'}` }}></div>
            </Box>
            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: '#802161' }}>
                <Grid container justifyContent="center" style={{ padding: '2rem 2rem' }}>
                    {isMobile ? (
                        <Typography
                            variant="h1"
                            style={{ fontSize: '2.5rem', color: 'white', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                        >
                            SERVICIOS
                        </Typography>
                    ) : (
                        <Typography
                            variant="h1"
                            style={{ fontSize: '5rem', color: 'white', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                        >
                            SERVICIOS
                        </Typography>
                    )}
                </Grid>
                <Grid containter direction="row-reverse" alignItems="center" style={{ backgroundColor: '#802161', height: '43px' }}>
                    <img src={tabW} alt="tab" height={45} style={{ margin: '0 4rem 0 70%' }} />
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: 'white', padding: '6rem 2rem', zIndex: '1', position: 'relative' }}>
                <Grid container justifyContent="center">
                    <Grid item container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" paddingBottom={'4rem'}>
                        <Grid item xs={12} md={1} justifyContent="flex-end">
                            <CardMedia
                                component="img"
                                height="120"
                                image={LAB}
                                alt="green iguana"
                                style={{ backgroundColor: 'white', objectFit: 'contain', paddingBottom: '2rem' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography
                                variant="h1"
                                style={{ fontSize: '45px', color: '#802161', fontFamily: 'FuturaNormal', marginBottom: '2rem' }}
                            >
                                GEOFÍSICA DE POZO
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={12} justifyContent="center" paddingX={isMobile ? '0rem' : '3rem'}>
                        <Grid item container xs={12} md={12} justifyContent="flex-start" alignItems="center" direction="column">
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{ fontSize: isMobile ? '18px' : '26px', color: '#8e3a73', fontFamily: 'FuturaMediana' }}
                            >
                                La geofísica aplicada usa los principios básicos de la ciencia para estudiar la parte más superficial de la
                                tierra a fin de describir y cuantificar reservas de interés económico para su explotación, como yacimientos
                                minerales, depósitos de hidrocarburos, reservas de agua subterráneas y otros, también es una gran
                                herramienta para ayudar a solucionar problemas geotécnicos en diferentes obras, como túneles, embalses, mina
                                subterránea y a rajo abierto a fin de preservar su estabilidad y seguridad. Existen varios métodos
                                geofísicos los cuales aprovechan las propiedades físicas de las rocas. Pero todos dan informaciones
                                indirectas, es decir nunca sale una muestra de una roca.
                            </Typography>
                        </Grid>

                        <Grid item container xs={12} md={6} justifyContent="flex-start" alignItems="center" direction="column">
                            <Typography
                                variant="h1"
                                align="center"
                                style={{ color: '#802161', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                            >
                                Medición de Trayectoria de Pozos con Giroscopio
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{ fontSize: isMobile ? '18px' : '26px', color: '#8e3a73', fontFamily: 'FuturaMediana' }}
                            >
                                La planificación de las operaciones mineras requiere herramientas precisas de medición de trayectoria que
                                aseguren el control de calidad. Con millones de dólares invertidos en una operación minera, incluso con el
                                error más pequeño en la precisión de una herramienta de medición de trayectoria de pozos, puede generar
                                pérdidas de mucho tiempo operativo y cantidad significativa de dinero.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                Las herramientas de desviación giroscópicas utilizan chips mems para proporcionar la orientación de la
                                sonda.{' '}
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {' '}
                                También se utilizan para proporcionar la inclinación de la sonda. Estas herramientas pueden ser operadas
                                mediante puntos de lectura discretos.{' '}
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                Las inspecciones giroscópicas pueden utilizarse en pozos con tubos de acero y en mineralizaciones
                                magnéticas.
                            </Typography>

                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                Rocktest SpA., cuenta con equipos MEMS y Icefield GT3 y GT4. Los sistemas de micro-electromecánicos o MEMS
                                giroscopio, es un circuito integrado de detección inercial que mide el ángulo y la velocidad de rotación en
                                un objeto o sistema. Programable para aplicaciones específicas, esta tecnología se basa en tres ejes
                                dimensionales de detección, que son X, Y, y Z. Los dispositivos pueden rastrear con precisión los
                                movimientos complejos sin ser influenciados por factores tales como la gravedad o campos magnéticos.
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} md={6} justifyContent="flex-start" direction="column">
                            <Typography
                                variant="h1"
                                align="center"
                                style={{ color: '#802161', fontFamily: 'FuturaNormal', marginBottom: '2.5rem' }}
                            >
                                Orientación de Estructuras de Pozos
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{ fontSize: isMobile ? '18px' : '26px', color: '#8e3a73', fontFamily: 'FuturaMediana' }}
                            >
                                El perfilaje de pozos mediante imágenes se ha convertido en un método cada vez más importante en los
                                estudios estructurales y geológicos en general. Entre las aplicaciones más comunes se encuentran las
                                siguientes: orientación de estructuras . medición de abertura de estructuras. orientación de perforaciones.
                                correlaciones litológicas. determinación de calidad de la roca. identificación de estratificación y
                                mineralización. Rocktest SpA., para este servicio cuenta con televisores ópticos y acústicos, de última
                                generación con capacidad de registrar imágenes de alta resolución, hasta 2mm de resolución vertical. Se
                                graban imágenes continuas de las paredes de los pozos para poder identificar las estructuras presentes en
                                ella, sus aberturas, manteo y dirección de Manteo real.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                Entre las aplicaciones más comunes se encuentran las siguientes:
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '12px'
                                }}
                            >
                                {`>`}Orientación de estructuras.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Medición de abertura de estructuras.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Orientación de perforaciones.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Correlaciones litológicas.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Determinación de calidad de la roca.
                            </Typography>
                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '8px'
                                }}
                            >
                                {`>`}Identificación de estratificación y mineralización.
                            </Typography>

                            <Typography
                                variant="body1"
                                align="justify"
                                style={{
                                    fontSize: isMobile ? '18px' : '26px',
                                    color: '#8e3a73',
                                    fontFamily: 'FuturaMediana',
                                    marginTop: '12px'
                                }}
                            >
                                Rocktest SpA., para este servicio cuenta con televisores ópticos y acústicos, de última generación con
                                capacidad de registrar imágenes de alta resolución, hasta 2mm de resolución vertical. Se graban imágenes
                                continuas de las paredes de los pozos para poder identificar las estructuras presentes en ella, sus
                                aberturas, manteo y dirección de Manteo real.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Contacto />
            <Footer />
        </>
    );
};

export default LandingPosoService;
